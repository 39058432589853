import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/bialystok2/1.jpg"
import photo2 from "../../assets/images/realizations/bialystok2/2.jpg"
import photo3 from "../../assets/images/realizations/bialystok2/3.jpg"
import mariampol from "../../assets/images/realizations/mariampol/mariampol.jpg"
import czyzew from "../../assets/images/realizations/czyzew/czyzew.jpg"
import ustka from "../../assets/images/realizations/ustka/ustka.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import {WithIntl} from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"

const Bialystok2Page = ({ data, language }) => {
  const images = [photo1, photo2, photo3]
  const otherRealizations = [
    {
      image: mariampol,
      name: localize("185"),
      type: localize("187"),
      url: "mariampol"
    },
    {
      image: czyzew,
      name: localize("288"),
      type: localize("294"),
      url: "czyzew"
    },
    {
      image: ustka,
      name: localize("289"),
      type: localize("187"),
      url: "ustka"
    },
  ]
  return (
    <>
      <RealizationInfo open={1} closed={5} name={localize("184")} image={data.file.childImageSharp.fixed}>
        <li className="gray600">{localize("320")}</li>
        <li className="gray600">{localize("321")}</li>
        <li className="gray600">{localize("322")}</li>
        <li className="gray600">{localize("323")}</li>
        <li className="gray600">{localize("324")}</li>
        <li className="gray600">{localize("325")}</li>
        <li className="gray600">{localize("326")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations language={language} realizations={otherRealizations}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "bialystok-2" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(Bialystok2Page)
